<template>
  <v-container fluid>
    <v-row
      dense
      align="center"
    >
      <v-col cols="12" md="9">
        <v-row dense>
          <v-col cols="auto">
            <v-menu
              ref="menuFromDate"
              v-model="menuFromDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  class="caption"
                  :placeholder="$_strings.common.FROM_DATE"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="dayjs(filters.dateFrom).format('DD-MM-YYYY')"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                :max="filters.dateTo"
                v-model="filters.dateFrom"
                no-title
                @input="menuFromDate = false"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="d-flex align-center" cols="auto">-</v-col>
          <v-col cols="auto">
            <v-menu
              ref="menuToDate"
              v-model="menuToDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  class="caption"
                  outlined
                  :placeholder="$_strings.common.TO_DATE"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="dayjs(filters.dateTo).format('DD-MM-YYYY')"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                :min="filters.dateFrom"
                v-model="filters.dateTo"
                no-title
                @input="menuToDate = false"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="ml-md-auto"
      >
        <v-row dense justify="end">
          <v-col cols="auto">
            <filter-list
              :filters="filters"
              @fetchData="key_table_data_list += 1"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              placeholder="Cari Nomor Kargo"
              class="caption"
              dense
              hide-details
              outlined
              v-model="search"
              @keyup.native.enter="setSearch"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="setSearch">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <br>
    <table-data-list
      :filters="filters"
      :key="key_table_data_list"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import FilterList from './FilterList.vue';
import { dateFormat } from '../../helper/commonHelpers';
import TableDataList from './TableDataList.vue';

export default {
  name: 'dokumenPod-list',
  components: {
    FilterList,
    TableDataList,
  },
  data() {
    return {
      key_table_data_list: 0,
      menuFromDate: false,
      menuToDate: false,
      startDate: null,
      endDate: null,
      search: this.$route.query.title || '',
      filters: {
        shipper: this.$route.query.shipper || '',
        transporter: this.$route.query.transporter || '',
        driverName: this.$route.query.driverName || '',
        licensePlate: this.$route.query.licensePlate || '',
        flightCruiseNo: this.$route.query.flightCruiseNo || '',
        status: this.$route.query.status || '',
        title: this.$route.query.title || '',
        dateFrom: this.$route.query.dateFrom || dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
        dateTo: this.$route.query.dateTo || dayjs().format('YYYY-MM-DD'),
      },
    };
  },
  computed: {
    displayedHeader() {
      const { canUpdate } = this.userAccess;
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
      })).filter((header) => {
        if (!canUpdate) {
          return header.value !== 'action';
        }
        return header;
      });
    },
  },
  watch: {
    'filters.dateFrom': {
      handler(newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            dateFrom: newVal,
            page: 1,
          },
        });
        this.key_table_data_list += 1;
      },
      deep: true,
    },
    'filters.dateTo': {
      handler(newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            dateTo: newVal,
            page: 1,
          },
        });
        this.key_table_data_list += 1;
      },
      deep: true,
    },
  },
  methods: {
    dayjs,
    dateFormat,
    setSearch() {
      if (this.search !== this.$route.query.title) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            title: this.search,
            page: 1,
          },
        });
      }
      this.filters.title = this.search;
      this.key_table_data_list += 1;
    },
  },
};
</script>

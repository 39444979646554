<template>
  <v-data-table
    :loading="isLoading"
    :headers="displayedHeader"
    :items="items"
    item-key="id"
    :options.sync="pagination"
    :server-items-length="totalItems"
    :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
  >
    <template v-slot:[`item.title`]=items>
      <span>{{ items.item.title }}</span>
    </template>
    <template v-slot:[`item.pickupDate`]={item}>
      <p class="ma-0">{{dateFormat(item.pickupDate)}}</p>
      <span>{{dayjs(item.pickupDate).format('HH:mm')}}</span>
    </template>
    <template v-slot:[`item.companyName`]={item}>
      <span>{{ item.company && item.company.name || '-' }}</span>
    </template>
    <template v-slot:[`item.driverName`]={item}>
      <span>{{ item.driver && item.driver.name || '-' }}</span>
    </template>
    <template v-slot:[`item.flightCruiseNo`]={item}>
      <span>{{ item.flightCruiseNo || '-' }}</span>
    </template>
    <template v-slot:[`item.latestStatus`]=items>
      <span>{{ items.item.latestStatus }}</span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-tooltip top v-if="userAccess.canUpdate">
        <template v-slot:activator="{ on, attrs }">
          <v-btn  v-bind="attrs" v-on="on" x-small icon class="mr-2" color="primary" @click="editItem(item)" >
            <v-icon> mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{$_strings.common.EDIT}}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`footer.page-text`]="props">
      <span>
        {{$_strings.dokumenPod.PAGE_NAME}}
        <span v-if="items.length">
          {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
        </span>
      </span>
    </template>
  </v-data-table>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import dayjs from 'dayjs';

export default {
  name: 'table-list',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      items: [],
      headers: [
        {
          text: this.$_strings.dokumenPod.headerNoKargo,
          value: 'title',
          width: '150px',
        },
        {
          text: this.$_strings.dokumenPod.pickupDate,
          value: 'pickupDate',
          width: '150px',
        },
        {
          text: this.$_strings.order.SHIPPER,
          value: 'shipper',
          width: '100px',
        },
        {
          text: this.$_strings.dokumenPod.headerRekanan3PL,
          value: 'companyName',
          width: '150px',
        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'originLocation',
          width: '170px',
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destinationLocation',
          width: '170px',
        },
        {
          text: this.$_strings.dokumenPod.headerNamaPengirim,
          value: 'driverName',
          width: '100px',
        },
        {
          text: this.$_strings.order.VEHICLE_PLATE,
          value: 'licensePlate',
          width: '120px',
        },
        {
          text: this.$_strings.dokumenPod.headerFlightCruise,
          value: 'flightCruiseNo',
          width: '120px',
        },
        {
          text: this.$_strings.dokumenPod.headerStatus,
          value: 'latestStatus',
          width: '120px',
        },
        {
          text: this.$_strings.dokumenPod.headerAksi,
          value: 'action',
          sortable: false,
        },
      ],
      pagination: this.defaultPagination(),
      totalItems: 0,
    };
  },
  computed: {
    displayedHeader() {
      const { canUpdate } = this.userAccess;
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
      })).filter((header) => {
        if (!canUpdate) {
          return header.value !== 'action';
        }
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        this.fetchList();
      },
      deep: true,
    },
  },
  methods: {
    dayjs,
    dateFormat,
    skipEmptyStringObject,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    toggleLoading(boolean) {
      if (this.items.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    editItem(item) {
      const { dateFrom, dateTo } = this.filters;
      this.$router.push({
        name: 'dokumen-pod-edit-page',
        params: {
          cargoId: item.id,
        },
        query: {
          title: item.title,
          dateFrom,
          dateTo,
        },
      });
    },
    async fetchList() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: this.handleSortBy({ defaultValue: 'id,DESC', sortBy, sortDesc }),
        ...this.filters,
      };
      try {
        this.toggleLoading(true);
        const result = await this.$_services.dokumenPod.dokumenPodList(this.skipEmptyStringObject(filters));
        this.items = result.data.contents;
        this.totalItems = result.data.totalData;
      } finally {
        this.toggleLoading(false);
      }
    },
  },
};
</script>

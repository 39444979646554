<template>
  <div class="text-center d-flex">
    <v-menu
      bottom
      :close-on-content-click="closeOnContentClick"
      width="300"
      offset-y
      z-index="1"
      allow-overflow
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          hide-details
          height="10"
          class="caption"
          prepend-inner-icon="mdi-filter"
          :placeholder="$_strings.order.FILTER"
          outlined
          dense
          readonly
          v-bind="attrs"
          @click="closeOnContentClick = false"
          v-on="on"
        ></v-text-field>
      </template>
      <v-list
        width="300"
        height="500"
        class="overflow-auto"
      >
        <section v-for="item in itemsFilterAutoComplete" :key="item.id">
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">{{item.title}}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <common-auto-complete-items
                :type="item.type"
                :searchName="item.searchName"
                :item-value="item.value"
                :item-text="item.text"
                hide-details
                height="10"
                v-model="form[item.vModel]"
                :placeholder="item.placeholder"
                dense
                outlined
                class="caption"
                clearable
                @updateItems="item.updateItems"
                :items="itemsFilter[item.items]"
                :filter="pageFilters[item.filters]"
              />
            </v-list-item-content>
          </v-list-item>
        </section>
        <section>
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">Nama Pengirim</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <v-text-field
                dense
                outlined
                class="caption"
                placeholder="Semua Pengirim"
                v-model="form.driverName"
                hide-details
                clearable
              />
            </v-list-item-content>
          </v-list-item>
        </section>
        <section>
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">Nomor Kendaraan</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <v-tooltip v-model="showTooltipLicensePlate" bottom>
                <template v-slot:activator="{ }">
                  <v-text-field
                    dense
                    outlined
                    class="caption"
                    placeholder="A 1234 BC"
                    v-model="form.licensePlate"
                    hide-details
                    @input="handleTooltipLicensePlate"
                    @blur="showTooltipLicensePlate = false"
                    clearable
                  />
                </template>
                <p>Ex: A 1234 BC</p>
              </v-tooltip>
            </v-list-item-content>
          </v-list-item>
        </section>
        <section>
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">No Pelayaran/Penerbangan</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <v-text-field
                dense
                outlined
                class="caption"
                placeholder="Semua Nomor"
                v-model="form.flightCruiseNo"
                hide-details
                clearable
              />
            </v-list-item-content>
          </v-list-item>
        </section>
        <section>
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">Status</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <section
                v-for="(chip, index) in latestStatus"
                :key="chip"
                class="mb-2"
              >
                <v-chip
                  close
                  small
                  @click:close="removeLatestStatus(index)"
                >
                  <strong>{{ itemsLatestStatus.find((item) => item.value === chip) && itemsLatestStatus.find((item) => item.value === chip).text }}</strong>
                </v-chip>
              </section>
              <v-autocomplete
                hide-details
                :items="itemsLatestStatus.filter((item) => !(latestStatus.find((ls) => ls === item.value)))"
                @change="addLatestStatus"
                v-model="status"
                class="caption"
                placeholder="Pilih status"
                dense
              ></v-autocomplete>
            </v-list-item-content>
          </v-list-item>
        </section>
        <section class="pl-2 pr-2">
          <v-btn
            depressed
            color="primary"
            block
            @click="setFilter"
          >
            {{$_strings.common.APPLY}}
          </v-btn>
          <v-btn
            depressed
            class="mt-2 white--text"
            color="red"
            block
            @click="closeOnContentClick = true"
          >
            {{$_strings.common.CLOSE}}
          </v-btn>
        </section>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.setItemsFromQueryString();
  },
  data() {
    return {
      itemsFilterAutoComplete: [
        {
          id: 'customer',
          title: 'Nama Shipper',
          type: 'masterShipper',
          searchName: 'companyName',
          placeholder: 'Semua Shipper',
          text: 'abbreviation',
          value: 'abbreviation',
          vModel: 'shipper',
          items: 'itemsCustomers',
          updateItems: this.updateCustomerItems,
          filters: 'shipper',
        },
        {
          id: 'transporter',
          title: 'Rekanan 3PL',
          type: 'masterPartner',
          searchName: 'companyName',
          placeholder: 'Semua Rekanan 3PL',
          text: 'companyName',
          value: 'companyName',
          vModel: 'transporter',
          items: 'itemsTransporter',
          updateItems: this.updateTransporterItems,
          filters: 'transporter',
        },
      ],
      itemsFilter: {
        itemsCustomers: [],
        itemsTransporter: [],
      },
      pageFilters: {
        transporter: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        shipper: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
      },
      itemsLatestStatus: this.$_strings.dokumenPod.latestStatusPod,
      form: {
        shipper: this.$route.query.shipper || '',
        transporter: this.$route.query.transporter || '',
        driverName: this.$route.query.driverName || '',
        licensePlate: this.$route.query.licensePlate || '',
        flightCruiseNo: this.$route.query.flightCruiseNo || '',
      },
      latestStatus: this.$route.query.status ? this.$route.query.status.split(',') : [],
      closeOnContentClick: false,
      showTooltipLicensePlate: false,
      status: '',
    };
  },
  methods: {
    setItemsFromQueryString() {
      const { shipper, transporter } = this.form;
      if (shipper) {
        this.itemsFilter.itemsCustomers = [{
          abbreviation: shipper,
        }];
      }
      if (transporter) {
        this.itemsFilter.itemsTransporter = [{
          companyName: transporter,
        }];
      }
    },
    handleTooltipLicensePlate(value) {
      const pattern = /^([A-Za-z]{1,4})([\s]{1})([0-9]{1,5})([\s]{1})([A-Za-z]{0,3})$/;
      if (!pattern.test(value) && value) {
        this.showTooltipLicensePlate = true;
        return;
      }
      this.showTooltipLicensePlate = false;
    },
    updateCustomerItems(items) {
      this.itemsFilter.itemsCustomers = [...this.itemsFilter.itemsCustomers, ...items];
    },
    updateTransporterItems(items) {
      this.itemsFilter.itemsTransporter = [...this.itemsFilter.itemsTransporter, ...items];
    },
    addLatestStatus(value) {
      this.latestStatus.push(value);
    },
    removeLatestStatus(index) {
      this.latestStatus.splice(index, 1);
      this.status = '';
    },
    setFilter() {
      this.closeOnContentClick = true;
      const {
        shipper,
        transporter,
        driverName,
        licensePlate,
        flightCruiseNo,
      } = this.form;
      const oldFilters = { ...this.filters };
      this.filters.shipper = shipper;
      this.filters.transporter = transporter;
      this.filters.driverName = driverName;
      this.filters.licensePlate = licensePlate;
      this.filters.flightCruiseNo = flightCruiseNo;
      this.filters.status = this.latestStatus.toString();
      if (JSON.stringify(oldFilters) !== JSON.stringify(this.filters)) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            ...this.filters,
          },
        });
      }
      this.$emit('fetchData');
    },
  },
};
</script>

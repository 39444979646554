var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"id","options":_vm.pagination,"server-items-length":_vm.totalItems,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(items){return [_c('span',[_vm._v(_vm._s(items.item.title))])]}},{key:"item.pickupDate",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.dateFormat(item.pickupDate)))]),_c('span',[_vm._v(_vm._s(_vm.dayjs(item.pickupDate).format('HH:mm')))])]}},{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.company && item.company.name || '-'))])]}},{key:"item.driverName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.driver && item.driver.name || '-'))])]}},{key:"item.flightCruiseNo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.flightCruiseNo || '-'))])]}},{key:"item.latestStatus",fn:function(items){return [_c('span',[_vm._v(_vm._s(items.item.latestStatus))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.dokumenPod.PAGE_NAME)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }